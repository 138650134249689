<div class="mx-auto border-2 bg-white max-w-7xl p-4">
  <div class="header">
    <h5>Tên người nộp thuế: {{ getSelectedCompany.tenDoanhNghiep }}</h5>
    <h5>Mã số thuế: {{ getSelectedCompany.mst }}</h5>
    <div class="titleTable text-center mt-2 border-2 border-black py-2">
      <p class="font-bold">BẢNG TỔNG HỢP PHÁT SINH PHẢI THU 131</p>
    </div>
    <p class="text-center font-normal mt-1 text-sm">Năm {{ getReportYear }}</p>
  </div>

  <div class="dataTable hscroll mt-2 outer-wrapper">
    <table
      class="
        table-fixed
        min-width-table
        border
        text-center
        w-full
        text-sm
        mb-40
      "
    >
      <thead>
        <tr class="bg-yellow-400">
          <th class="w-12">STT</th>
          <th>Người mua</th>
          <th class="w-14">Tình trạng</th>
          <th class="w-32">MST</th>
          <th class="w-32">Số Tiền</th>
          <th class="w-20">Loại tiền tệ</th>
        </tr>
      </thead>

      <tbody>
        <template
          v-for="(records, currency) in report_131.records"
          :key="currency"
          v-if="report_131 && report_131.records"
        >
          <tr v-for="(record, idx) in records" :key="idx">
            <td>{{idx + 1}}</td>
            <td class="text-left">
              <span class="whitespace-normal">{{record[1].company_name}}</span>
            </td>
            <td>
              <Status :status="record[1].status" />
            </td>
            <td>{{record[0]}}</td>
            <td>
              <input-money
                v-model.lazy="record[1].sum_product_amount"
                class="money"
                :invoiceStyle="true"
                :table="true"
                :decima="0"
              />
            </td>
            <td>{{ currency }}</td>
          </tr>
          <tr v-if="is_load_more">
            <td colspan="6" class="text-yellow-700">
              <p class="p-2 font-bold">
                <i class="el-icon-warning-outline mr-2" />Dữ liệu có thể thiếu,
                vui lòng tải tệp để xem dữ liệu đầy đủ
              </p>
            </td>
          </tr>

          <tr class="bg-yellow-400 font-bold">
            <td colspan="4">
              <p class="p-1 text-left">Tổng ({{currency}})</p>
            </td>
            <td>
              <input-money
                v-model.lazy="report_131.aggregates[currency]"
                class="money font-bold"
                :invoiceStyle="true"
                :table="true"
                :decima="0"
              />
            </td>
            <td>{{currency}}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="6"><p class="p-3">Dữ liệu trống</p></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
